import React, { useState, useEffect, useRef } from 'react';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import Gradient from '../../components/Gradient/Gradient'; 
import cheekyDemo from '../../assets/cheeky-demo.mp4';
import neuroPixel from '../../assets/graph_sequence.gif';
import Modal from '../../components/Modal/Modal';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Projects.css';

function Projects() {
  const canvasRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
     {
      id: 2,
      title: 'Co-founder, CPO, Full-stack Developer',
      heading : 'Cheeky',
      description: 'Cheeky is a web and IOS application that creates a centralized, personalized, and effortless shopping experience. I learned a lot about scaling a full application from the ground up, and excited for our launch soon!',
      videoUrl: cheekyDemo,
      link: 'https://www.cheeky.shopping/'
    },
    {
      id: 1,
      title: 'Research under UNC-CH Statistics Department',
      heading : 'Neuropixel Data Analysis',
      description: 'We have been able to utilize ML and DL models to predict the visual stimuli a given mouse is seeing with up to 98% accuracy, based solely on their neural firing rates. Ray and I will be presenting at the CNS and CCN this month to present our findings!',
      imageUrl: neuroPixel,
      link: 'https://github.com/RayCarpenterIII/Neuropixel-Analysis'
    },
    {
      id: 3, 
      // title: 'Project 3',
      heading : 'More Projects coming soon!',
      // description: 'Project 2 is an interactive data visualization tool built with D3.js and React.',
      // imageUrl: 'https://via.placeholder.com/200x150?text=Project2'
      //videoUrl: 'path/to/your/video.mp4'
      link: 'https://www.linkedin.com/in/cole-vita/'
    },
  ];

  useEffect(() => {
    if (canvasRef.current) {
      new Gradient({
        canvas: canvasRef.current,
        // colors: ['#153448', '#3C5B6F', '#948979', '#DFD0B8'],
        //Teal
        colors: ['#003C43', '#135D66', '#77B0AA', '#E3FEF7'],
        //Eliome colors:
        // colors: ['#153448', '#f6995c', '#948979', '#DFD0B8'],
        density: [0.06, 0.16]
      });
    }
  }, []);

  // const openModal = (project) => {
  //   console.log(`Opening modal for project: ${project.title}`);
  //   setSelectedProject(project);
  //   setModalOpen(true);
  //   document.body.classList.add('modal-open');
  //   document.querySelector('.projects-grid').classList.add('hidden');
  //   document.getElementById('heading').classList.add('hidden');
  // };


  const closeModal = () => {
    console.log('Closing modal');
    setModalOpen(false);
    setSelectedProject(null);
    document.body.classList.remove('modal-open');
    document.querySelector('.projects-grid').classList.remove('hidden');
    document.getElementById('heading').classList.remove('hidden');
  };

  const handleProjectClick = (project) => {
    if (project.link) {
      window.open(project.link, '_blank');
    };
  } 

  return (
    <div className="projects-container">
      <canvas ref={canvasRef} id="gradient-canvas"></canvas>
      <Link to="/" className="home-icon">
        <FaHome />
      </Link>
      <div className="content">
        <h2 id = "heading">Featured Work.</h2>
        <div className="projects-grid">
        {projects.map(project => (
          <ProjectCard
            key={project.id}
            title={project.title}
            heading={project.heading}
            description={project.description}
            imageUrl={project.imageUrl}
            videoUrl={project.videoUrl}
            onClick={() => handleProjectClick(project)}
          />
        ))}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        project={selectedProject}
      />
    </div>
  );
}

export default Projects;
