import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Gradient from '../../components/Gradient/Gradient';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';  
import Modal from '../../components/Modal/Modal';
import './Landing.css';

function Landing() {
  const canvasRef = useRef(null);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const resumePDFUrl = process.env.PUBLIC_URL + '/resume.pdf';
  const email = 'colevita@gmail.com';

  useEffect(() => {
    if (canvasRef.current) {
      new Gradient({
        canvas: canvasRef.current,
        //Beige / Green 
        // colors: ['#1A4D2E', '#4F6F52', '#E8DFCA', '#F5EFE6'],
        // Beige / Blue
        // colors: ['#153448', '#3C5B6F', '#948979', '#DFD0B8'],
        // Brown tones 
        colors: ['#AD8B73', '#CEAB93', '#E3CAA5', '#FFFBE9'],
        //Green tones
        colors: ['#D6EFD8', '#80AF81', '#508D4E', '#1A5319'],
        density: [0.06, 0.16]

        //https://colorhunt.co/palettes/popular
      });
    }
  }, []);

  const openResumeModal = () => {
    setIsResumeModalOpen(true);
  };

  const closeResumeModal = () => {
    setIsResumeModalOpen(false);
  };

  return (
    
    <div className="landing">
      <canvas ref={canvasRef} id="gradient-canvas"></canvas>
      <div className={`content ${isResumeModalOpen ? 'hidden' : ''}`}>
        <div className="content">
          <div className="header">
            <h1>Cole Vita</h1>
          </div>
        <div className="intro-box">
          {/* <h3>My work</h3> */}
            <p>
                I love to work with passionate people building scalable, innovative projects.
              </p>
              <p>
                My experience spans full-stack development, machine learning / deep learning, and cloud infrastructure. I'm always looking for new opportunities to learn and grow.
              </p>
              <p>
                I am currently attending UNC-Chapel hill pursuing a B.S. in Computer Science and a Minor in Data Science and statistics.
              </p>
              {/* <h3>A little bit more about me</h3> */}
              <p>
                I'm somewhat health-obsessed, life is more fun when your body and mind are operating well. I plan to add a section to this website soon with some of the my favorite health tips, but for now Peter Atilla and Andrew Huberman have amazing content to start!
              </p>
              <p>
                I'm from Miami, Florida and grew up playing ice hockey. I found a passion for Public Forum Debate in high school, where I won the Florida State Championship and was ranked top-20 in the nation my senior year (still one of my favorite memories!).
              </p>
        </div>
          <div className="cta-buttons">
            <Link to="/projects" className="cta-button">View Projects</Link>
            <button onClick={openResumeModal} className="cta-button">Resume</button>
          </div>
          <div className="social-links">
            <a href="https://github.com/cole-vita-unc" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/cole-vita/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href={`mailto:${email}`}>
              <FaEnvelope />
            </a>
          </div>
          
          {/* <div className="intro-box">
            <h3>A little bit more about me</h3>
              <p>
                I'm somewhat health-obsessed, I believe life is way more fun when your body and mind are operating near their full potentials. I plan to add a section to this website soon with some of the my favorite health tips, but for now Peter Atilla and Andrew Huberman have amazing content to start!
              </p>
              <p>
                I'm from Miami, Florida and grew up playing ice hockey while also finding a passion for Public Forum Debate in High school. Winning the Florida State Championship and being ranked top-20 in the nation my senior year is still one of my favorite memories in high school.
              </p>
          </div> */}

        </div>
      </div>
      <Modal
        isOpen={isResumeModalOpen}
        onClose={closeResumeModal}
        content={
          <div className="resume-modal">
            <object
              data={resumePDFUrl}
              type="application/pdf"
              width="100%"
              height="780px"
            >
              <p>Unable to display PDF file. <a href={resumePDFUrl}>Download</a> instead.</p>
            </object>
          </div>
        }
      />
    </div>
  );
}

export default Landing;