import React from 'react';
import './ProjectCard.css';

const ProjectCard = ({ title, heading, description, imageUrl, videoUrl, onClick }) => {
  return (
    <div className="project-card" onClick={onClick}>
      <div className="project-info">
        <div className="project-description">
          <h3>{title}</h3>
          <p>{description}</p>
          <p className="learn-more">Click to learn more!</p>
        </div>
        <div className="project-heading">
          <h2>{heading}</h2>
        </div>
      </div>
      {videoUrl || imageUrl ? (
        <div className="project-media">
          {videoUrl ? (
            <video autoPlay loop muted playsInline>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={imageUrl} alt={title} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProjectCard;